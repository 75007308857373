import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="#" className="social-icon">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-spotify"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-codepen"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-github"></i>
        </a>
        <a href="#" className="social-icon">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
      <div className="icon">
        <img src="icon.png" alt="LinkedIn" />
      </div>
      <p className="copyright">© Lily Pham 2024</p>
    </footer>
  );
};

export default Footer;
