import React from 'react';
import './projects.css';
import { ReactComponent as FolderIcon } from '../files/folder.svg'

const projects = [
  {
    title: "Feelance Marketplace",
    description:
      "Full stack mobile application that uses relational databases for students to buy and sell services",
    technologies: ["Flask", "Swift", "SQL"],
    link: "#",
  },
  {
    title: "QueueMeIn",
    description:
      "Office hours manager used by 2000+ students, TA's, and professors",
    technologies: ["Next.js", "CSS", "Firebase"],
    link: "#",
  },
  {
    title: "Search Algorithm Visualizer",
    description:
      "Find out how we built a custom headless CMS with Node, Express, and Firebase for a project at Upstatement",
    technologies: ["Algorithms", "Express", "React"],
    link: "#",
  },
  {
    title: "Financial Document Analyzer",
    description:
      "Uses openAI API to do document analaysis, comparisons, and ",
    technologies: ["Next.js", "Chart.js", "GitHub API"],
    link: "#",
  },
  {
    title: "CookCraft",
    description:
      "Mobile application that uses image recognition and NLP to serve as a digital inventory and reconmmends sustainable recipies for users",
    technologies: ["YOLOV5", "Firebase"],
    link: "#",
  },
];

const Projects = () => {
  return (
    <section id="projects" className='projects'>
      <h2>My Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <FolderIcon className="folder-icon"/>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <div className="technologies">
              {project.technologies.map((tech, i) => (
                <span key={i} className="tech">
                  {tech}
                </span>
              ))}
            </div>
            <a href={project.link} className="external-link" target="_blank" rel="noopener noreferrer">
              ↗
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
