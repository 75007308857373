import React from "react";
import "./experience.css";

const experiences = [
  {
    company: "Microsoft",
    role: "Software Engineer Intern",
    duration: "May 2024 - Aug 2024",
  },
  {
    company: "RedHat",
    role: "Product Manager Intern",
    duration: "May 2024 - Aug 2024",
  },
  {
    company: "Wells Fargo",
    role: "Data Engineering Intern",
    duration: "June 2023 - Aug 2023",
  },
];

const Experience = () => {
  return (
    <section id='experience' className="experience-section">
      <h2 className="experience-title">EXPERIENCE</h2>
      <div className="experience-list">
        {experiences.map((exp, index) => (
          <div key={index} className="experience-item">
            <div>
              <h3 className="experience-company">{exp.company}</h3>
              <p className="experience-role">{exp.role}</p>
            </div>
            <p className="experience-duration">{exp.duration}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;
